<template>
    <div id="plan-contract"> 
      <div class="row p-0 m-0">
        <div class="col-sm-12 col-xs-12 col-md-7">
          <div class="row p-0 m-0">
            <div class="col-12 p-0">
              <table width="100%" role="table" aria-busy="false" class="table b-table position-relative mb-1 wd-25">
                <thead role="rowgroup" > 
                  <tr role="row" >
                    <th role="columnheader" scope="col">Plan</th>
                    <th role="columnheader" scope="col">Precio</th>
                    <th role="columnheader" scope="col">Meses</th>
                    <th role="columnheader" scope="col">Subtotal</th>
                  </tr>
                </thead>
                <tbody role="rowgroup" class="background-white">
                    <tr role="row">
                      <td aria-colindex="1" role="cell" class="pink">
                        Oro x {{form.how_many_projects}}
                      </td>
                      <td aria-colindex="1" role="cell">
                        ${{formatPrice(form.price)}}
                      </td>
                      <td aria-colindex="1" role="cell">
                        <v-select 
                            v-model="form.how_many_months"
                            label="name"
                            :clearable="false"
                            placeholder="Meses"
                            :options="months">
                            <span slot="no-options">No se encontraron opciones</span>
                        </v-select>
                      </td>
                      <td aria-colindex="1" role="cell">
                        ${{formatPrice(form.subtotal)}}
                      </td>
                    </tr>
                </tbody>
              </table>
            </div> 
            <div class="col-sm-12 col-xs-12 col-md-6 offset-md-6 p-0 align-self-end ">
              <table width="100%" role="table" aria-busy="false" class="table b-table position-relative mb-1 wd-50">
                <thead role="rowgroup" > 
                  <tr role="row" >
                    <th role="columnheader" scope="col">Total</th>
                    <th role="columnheader" scope="col">&nbsp;</th>
                  </tr>
                </thead>
                <tbody role="rowgroup" class="background-white">
                    <tr role="row">
                      <td aria-colindex="1" role="cell" class="pink">
                        Subtotal
                      </td>
                      <td aria-colindex="1" role="cell">
                        ${{formatPrice(form.subtotal)}}
                      </td> 
                    </tr>
                    <tr role="row">
                      <td aria-colindex="1" role="cell" class="pink">
                        Total
                      </td>
                      <td aria-colindex="1" role="cell">
                        ${{formatPrice(form.total)}}
                      </td> 
                    </tr>
                </tbody>
              </table>
              <button class="btn btn-primary w-100" type="button" @click="finalizarSubscripcion('oro')">Finalizar subscripcion</button>
            </div>
          </div>
        </div>
        <div class="col-sm-12 col-xs-12 col-md-5 text-center">
          <img :src="imgTable" class="img_table">
        </div>
      </div>
    </div>
</template>

<script> 
  import {mapActions,mapState} from 'vuex' 

  export default{
    name: 'PlansContract',
    components: {},
    data(){
        return {
          imgTable: require('@/assets/images/plans/sub.svg'),
          months:[],
          form:{
          }
        }
    },
    watch: {
        form:{
            deep: true,
            handler(){    
                this.saveFiltersPlans(this.form);
                this.calculateTable();
            }
        },
    },
    mounted(){ 
      this.form = this.plans;
      for (let index = 1; index <= parseInt(this.plans.how_many_months) + 13; index++) {
        this.months.push(index);
      } 
      this.calculateTable();
    },
    methods: { 
      ...mapActions('plans', ['saveFiltersPlans']),
      formatPrice(value) {
          let val = (value/1).toFixed(0).replace('.', ',')
          return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
      },
      calculateTable(){
        this.form.subtotal = this.form.price * this.form.how_many_months;
        this.form.total = this.form.subtotal;
      }
    },
    computed: { 
      ...mapState('plans',['plans']), 
    },
  }
</script>

<style scoped>
.background-white{
  background: white;
}
.pink{
  color: #EA387F;
  font-weight: 500;
}
.wd-25 th{
  width: 25%;
}
.wd-50 th{
  width: 50%;
}
@media (max-width: 767px) {
  .img_table{
    margin-top: 40px;
  }
} 
</style>